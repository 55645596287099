<template>
  <div>
    <h2 v-if="!newPerson">Edit</h2>
    <h2 v-else>Nueva persona</h2>

    <v-text-field v-model="person.name" label="nombre"></v-text-field>

    <div v-for="(ln, i) in person.lastname" :key="i">
      <v-text-field v-model="person.lastname[i]" :label="'Apellido ' + (i + 1)"></v-text-field>
    </div>
    <v-btn @click="person.lastname.push('')" flat color="primary">añadir apellido</v-btn>

    <v-text-field v-model="person.nickname" label="nickname"></v-text-field>
    <v-select :items="options.genders" v-model="person.gender" label="Sexo"></v-select>
    <!-- hijos -->
    <div style="margin-top: 10px">
      <h3>Familia</h3>
      <div v-for="(rel, i) in person.relatives" :key="i">
        <div style="display: flex; justify-content: space-between">
          <PersonMiniCard :id="rel.id" :relation="rel.relation" />
          <div>
            <v-btn icon flat color="error" size="x-small" @click="removeRelation(i)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="newRelative.id" label="ID de la persona"></v-text-field>
          </v-col>
          <v-col>
            <v-select v-model="newRelative.relation" :items="options.relations"></v-select>
          </v-col>
        </v-row>
        <v-btn flat color="primary" @click="pushRelative()">Añadir</v-btn>
      </div>
    </div>

    <v-btn @click="saveIt()" flat color="primary" block style="margin-top: 20px">Save!</v-btn>
    {{ person }}
  </div>
</template>

<script>
/*
const { v4: uuidv4 } = require('uuid')
const uniqueId = uuidv4()
*/
import PersonMiniCard from '@/components/PersonMiniCard.vue'
export default {
  components: {
    PersonMiniCard,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  watch: {
    id() {
      this.fetchMe()
    },
  },
  data() {
    return {
      newPerson: true,
      options: {
        relations: [
          { title: 'Padre', value: 'father' },
          { title: 'Hijo', value: 'children' },
          { title: 'Madre', value: 'mother' },
          { title: 'Pareja', value: 'spouse' },
        ],
        genders: [
          { title: 'Hombre', value: 'male' },
          { title: 'Mujer', value: 'female' },
        ],
      },
      newRelative: { relation: '', id: '' },

      key: 'value',
      person: {
        name: 'asf',
        lastname: ['González-Aller'],
        relatives: [],
      },
    }
  },
  methods: {
    removeRelation(index) {
      this.person.relatives.splice(index, 1)
    },
    async fetchMe() {
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${this.id}&token=${localStorage.token}`
      let x = await fetch(url, {
        method: 'GET',
        // headers: { Auhtentication: localStorage.token },
      })
      if (x.ok) {
        this.newPerson = false
        this.person = await x.json()
      } else {
        this.newPerson = true

        this.person = { id: this.id, name: '', lastname: [''], relatives: [] }
      }
    },
    pushRelative() {
      if (!this.person.relatives) this.person.relatives = []
      this.person.relatives.push({ ...this.newRelative })

      this.newRelative = { id: '', relation: '' }
    },
    async saveIt() {
      const token = localStorage.token
      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?token=${token}`
      let x = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.person),
        //   headers: { Auhtentication: token },
      })
      if (x.ok) alert('Success!')
      else alert('Ouch. Error...')
    },
  },
  mounted() {
    this.fetchMe()
  },
}
</script>

<style lang="scss" scoped></style>
