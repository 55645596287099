<template>
  <div
    style="
      border: 1px solid grey;
      padding: 5px 20px;
      border-radius: 5px;
      margin: 8px 0px;
      cursor: pointer;
    "
    @click="goToMe"
  >
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else>
      <div style="display: flex; justify-content: space-between">
        <div>{{ person.name }} {{ person.lastname.slice(0, 2).join(' ') }}</div>
        <div>{{ relation }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    relation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      error: '',
      person: { id: '', lastname: [] },
    }
  },

  methods: {
    goToMe() {
      this.$router.push('/v2/edit/' + this.id)
    },
    async fetchData() {
      const id = this.id
      const token = localStorage.token // this.$store.token
      console.log('id', id)
      console.log('tokenn', token)

      const url = `https://3qvcuoiu2tcqghh73a7jzeu4vq0rsiqq.lambda-url.us-east-1.on.aws/person?id=${id}&token=${token}` //&token=`
      let x = await fetch(url, {
        method: 'GET',
        // headers: { Authentication: token },
      })
      if (x.ok) {
        const j = await x.json()
        if (!j.lastname) j.lastname = []
        this.person = j
      } else {
        this.error = await x.text()
      }
    },
  },
  mounted() {
    this.fetchData()
    // console.log('token', this.$store.token)
  },
}
</script>

<style lang="scss" scoped></style>
